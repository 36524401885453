.simple-grid-modal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .simple-grid-modal-wrapper .simple-grid-modal-container {
    max-width: 90%;
    height: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff; }
    .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-header {
      padding: 10px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-bottom: 2px solid black; }
      .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-header p {
        max-width: 80%;
        color: #4a4a4a;
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
        margin-bottom: 0; }
      .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-header .close-button {
        color: #00acf1;
        font-family: 'Font Awesome 5 Pro';
        font-size: 20px;
        font-weight: 300;
        position: absolute;
        right: 2%;
        cursor: pointer; }
    .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body {
      width: 100%;
      padding: 15px;
      flex: 1;
      overflow-y: auto; }
      .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body .simple-grid-table-wrapper {
        display: table;
        width: 100%;
        table-layout: auto;
        border-collapse: collapse; }
        .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body .simple-grid-table-wrapper .simple-grid-table-row {
          display: table-row; }
        .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body .simple-grid-table-wrapper .simple-grid-table-header {
          background-color: #48bbec;
          color: white;
          font-weight: bold; }
        .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body .simple-grid-table-wrapper .simple-grid-table-cell {
          display: table-cell;
          padding: 10px;
          border: 1px solid #ccc;
          text-align: left; }
        .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body .simple-grid-table-wrapper .simple-grid-table-header-cell {
          padding: 10px;
          border: 1px solid #ccc;
          text-align: center; }
        .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body .simple-grid-table-wrapper .simple-grid-table-header-cell-first-column {
          min-width: 400px;
          max-width: 500px; }
        .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body .simple-grid-table-wrapper .simple-grid-table-header-cell-field-column {
          min-width: 300px;
          max-width: 300px; }
        .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body .simple-grid-table-wrapper .simple-grid-table-row {
          display: table-row; }
        .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body .simple-grid-table-wrapper .simple-grid-table-cell-first-column {
          min-width: 400px;
          max-width: 500px;
          font-weight: bold; }
        .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-body .simple-grid-table-wrapper .simple-grid-table-cell-input {
          width: 100%;
          border: 0.5px solid #ccc;
          padding: 5px; }
    .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-top: 1px solid #ccc; }
      .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-footer .simple-grid-modal-footer-summary-wrapper {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .simple-grid-modal-wrapper .simple-grid-modal-container .simple-grid-modal-footer .simple-grid-modal-footer-summary-wrapper .simple-grid-modal-footer-summary-label {
          font-size: 16px;
          color: #989898;
          text-align: center;
          margin: 0 0 10px 0; }

.simple-grid-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; }
