.menu-price-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 767px) {
    .menu-price-container .react-pdf__Page__canvas {
      width: 100%  !important;
      height: 100%  !important; } }
  @media (max-width: 767px) {
    .menu-price-container .react-pdf__Page__textContent {
      width: 100%  !important; } }
