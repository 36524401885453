#create-form-root {
  width: 100%;
  height: 100%;
  padding-left: 26px;
  display: flex;
  flex-direction: row; }
  #create-form-root .create-form-wrapper {
    width: 100%;
    height: 100%; }
    #create-form-root .create-form-wrapper .create-form-header {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center; }
      #create-form-root .create-form-wrapper .create-form-header i {
        color: #424c9f;
        font-size: 18px; }
      #create-form-root .create-form-wrapper .create-form-header p {
        color: #4a4a4a;
        font-size: 24px;
        margin-bottom: 0; }
    #create-form-root .create-form-wrapper .form-content {
      width: 100%;
      display: flex;
      flex-direction: row; }
      #create-form-root .create-form-wrapper .form-content .form-left {
        width: 50%; }
      #create-form-root .create-form-wrapper .form-content .buttons-wrapper {
        width: 100%;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center; }
        #create-form-root .create-form-wrapper .form-content .buttons-wrapper .form-create-button {
          height: 45px;
          width: 150px;
          border-radius: 25px;
          font-size: 18px;
          line-height: 24px;
          background-color: #00acf1;
          color: #ffffff;
          text-transform: capitalize; }
          #create-form-root .create-form-wrapper .form-content .buttons-wrapper .form-create-button:focus {
            outline: none; }
          #create-form-root .create-form-wrapper .form-content .buttons-wrapper .form-create-button:hover {
            background-color: #00acf1;
            color: #ffffff; }
        #create-form-root .create-form-wrapper .form-content .buttons-wrapper .form-disabled-button {
          height: 45px;
          width: 150px;
          border-radius: 25px;
          font-size: 18px;
          line-height: 24px;
          background-color: rgba(0, 0, 0, 0.12);
          color: rgba(0, 0, 0, 0.26);
          text-transform: capitalize; }
          #create-form-root .create-form-wrapper .form-content .buttons-wrapper .form-disabled-button:focus {
            outline: none; }
          #create-form-root .create-form-wrapper .form-content .buttons-wrapper .form-disabled-button:hover {
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26); }

.overlay {
  z-index: 2000; }

.modal {
  display: block !important;
  position: unset !important; }
