.basic-container,
.form-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .basic-container .upgrade-company-logo,
  .form-wrapper .upgrade-company-logo {
    margin-bottom: 5%; }
    .basic-container .upgrade-company-logo .logo,
    .form-wrapper .upgrade-company-logo .logo {
      width: 300px; }
  .basic-container h1,
  .form-wrapper h1 {
    font-size: 36px;
    color: #35c1b8; }
  .basic-container h6,
  .form-wrapper h6 {
    font-size: 24px;
    color: #4a4a4a; }
  .basic-container .form-container,
  .form-wrapper .form-container {
    width: 30%;
    max-width: 40%; }
    .basic-container .form-container h1,
    .form-wrapper .form-container h1 {
      text-align: center;
      font-size: 36px;
      color: #35c1b8; }
  .basic-container .button-wrapper,
  .form-wrapper .button-wrapper {
    margin-top: 1%; }
    .basic-container .button-wrapper .upgrade-button,
    .form-wrapper .button-wrapper .upgrade-button {
      height: 40px;
      width: 190px;
      border-radius: 3px;
      font-size: 18px;
      line-height: 15px;
      background-color: #4a90e2;
      color: #ffffff; }
      .basic-container .button-wrapper .upgrade-button:hover,
      .form-wrapper .button-wrapper .upgrade-button:hover {
        background-color: #4a90e2;
        color: #ffffff; }
  .basic-container .upgradeform-submit,
  .form-wrapper .upgradeform-submit {
    height: 40px;
    width: 190px;
    float: right; }
  .basic-container .upgradeform-submit-active,
  .form-wrapper .upgradeform-submit-active {
    background-color: #4a90e2;
    color: #ffffff; }
    .basic-container .upgradeform-submit-active:hover,
    .form-wrapper .upgradeform-submit-active:hover {
      background-color: #4a90e2;
      color: #ffffff; }
