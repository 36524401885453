#sig-preview-modal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  #sig-preview-modal-wrapper .sig-preview-modal-container {
    width: 50%;
    height: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff; }
    @media (max-width: 700px) {
      #sig-preview-modal-wrapper .sig-preview-modal-container {
        height: 60%;
        width: 85%; } }
    #sig-preview-modal-wrapper .sig-preview-modal-container .sig-preview-modal-header {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-bottom: 2px solid black; }
      #sig-preview-modal-wrapper .sig-preview-modal-container .sig-preview-modal-header p {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
        margin-bottom: 0; }
      #sig-preview-modal-wrapper .sig-preview-modal-container .sig-preview-modal-header .close-button {
        color: #00acf1;
        font-family: 'Font Awesome 5 Pro';
        font-size: 20px;
        font-weight: 300;
        position: absolute;
        right: 2%;
        cursor: pointer; }
    #sig-preview-modal-wrapper .sig-preview-modal-container .sig-preview-modal-body {
      width: 100%;
      height: calc(100% - 50px);
      overflow: auto;
      padding: 15px; }
      #sig-preview-modal-wrapper .sig-preview-modal-container .sig-preview-modal-body .doc-item {
        color: #007bff;
        cursor: pointer; }

.sig-canvas-wrapper {
  width: 90%;
  height: calc(100% - 70px);
  margin: 0px auto;
  background-color: lightgrey; }

.sig-canvas {
  width: 100%;
  height: 100%; }

.sig-btn-wrapper {
  height: 70px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .sig-btn-wrapper .sig-cancel-btn {
    height: 40px;
    width: 120px;
    border-radius: 45px;
    font-size: 16px;
    line-height: 24px;
    background-color: #ffffff;
    color: #9b9b9b;
    text-transform: capitalize;
    margin-right: 20px; }
    .sig-btn-wrapper .sig-cancel-btn:focus {
      outline: none; }
    .sig-btn-wrapper .sig-cancel-btn:hover {
      background-color: #ffffff;
      color: #9b9b9b; }
  .sig-btn-wrapper .sig-save-btn {
    height: 40px;
    width: 120px;
    border-radius: 45px;
    font-size: 16px;
    line-height: 24px;
    background-color: #4a90e2;
    color: #ffffff;
    text-transform: capitalize; }
    .sig-btn-wrapper .sig-save-btn:focus {
      outline: none; }
    .sig-btn-wrapper .sig-save-btn:hover {
      background-color: #4a90e2;
      color: #ffffff; }
