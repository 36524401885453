#workflow-inbox-root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; }
  #workflow-inbox-root .workflow-inbox-wrapper {
    width: 95%;
    height: 100%; }
    #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-header {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center; }
      #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-header .workflow-inbox-title-wrapper {
        width: 55%;
        display: flex;
        align-items: center; }
        #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-header .workflow-inbox-title-wrapper i {
          color: #424c9f;
          font-size: 18px; }
        #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-header .workflow-inbox-title-wrapper p {
          color: #4a4a4a;
          font-size: 24px;
          margin-bottom: 0;
          width: 85%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
    #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-filter-search {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-filter-search i {
        font-size: 18px;
        color: #424c9f; }
    #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px; }
      #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .views-picky {
        display: flex;
        align-items: center;
        width: 50%; }
        #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .views-picky p {
          color: #4a4a4a;
          margin-bottom: 0px;
          font-size: 16px;
          margin-right: 20px; }
        #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .views-picky button:focus {
          outline: none; }
        #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .views-picky .picky {
          width: 50%; }
      #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .picky__input {
        height: 40px;
        border: 1px solid #cfd3d4;
        border-radius: 3px;
        padding: 1px 7px 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .picky__input::after {
          top: 13px; }
      #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .picky__dropdown {
        border: 1px solid #cfd3d4; }
      #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .picky__input,
      #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .picky__dropdown,
      #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .option {
        font-size: 14px; }
      #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .picky__dropdown .option:focus,
      #workflow-inbox-root .workflow-inbox-wrapper .views-search-wrapper .picky__dropdown li:focus {
        outline: none !important; }
    #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content {
      width: 100%;
      height: 90%; }
      #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root {
        box-shadow: none !important;
        overflow-x: auto; }
        #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper {
          margin-bottom: 0; }
          #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper th {
            vertical-align: middle;
            height: 30px;
            width: 42px;
            font-size: 16px;
            color: #ffffff;
            word-wrap: break-word;
            text-align: left;
            padding: 4px 4px 4px 10px; }
          #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper td {
            vertical-align: middle;
            height: 30px;
            width: 42px;
            font-size: 14px;
            color: #4a4a4a;
            word-wrap: break-word;
            text-align: left;
            padding: 4px 4px 4px 10px; }
          #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper .tabel-head-wrapper {
            height: 30px;
            background-color: #00acf1;
            border-radius: 3px; }
            #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper .tabel-head-wrapper tr .checkbox-color svg {
              color: #ffffff; }
          #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper .tabel-body tr:nth-child(even) {
            background-color: #f1f4f7; }
          #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper .tabel-body tr:nth-child(odd) {
            background-color: #ffffff; }
          #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper .tabel-body tr td {
            max-width: 42px;
            border: none;
            border-right: solid 1px #979797; }
            #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper .tabel-body tr td:last-child {
              border-right: none; }
            #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper .tabel-body tr td svg {
              color: #979797; }
            #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper .tabel-body tr td i {
              color: #4a4a4a;
              font-size: 16px; }
          #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper .tabel-footer tr td {
            font-size: 15px;
            border: none !important; }
            #workflow-inbox-root .workflow-inbox-wrapper .workflow-inbox-content .paper-root .table-wrapper .tabel-footer tr td button:focus {
              outline: none; }
