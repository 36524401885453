#vendor-success-root {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  text-align: center; }
  #vendor-success-root .vendor-success-logo-wrapper {
    margin-bottom: 50px; }
    #vendor-success-root .vendor-success-logo-wrapper .logo {
      width: 250px; }
      @media (min-width: 1024px) {
        #vendor-success-root .vendor-success-logo-wrapper .logo {
          width: 300px; } }
  #vendor-success-root .vendor-success-content-wrapper {
    width: 90%;
    height: 100%;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      #vendor-success-root .vendor-success-content-wrapper {
        width: 100%; } }
    #vendor-success-root .vendor-success-content-wrapper p {
      color: #4a4a4a;
      font-size: 24px;
      margin-bottom: 0;
      margin-bottom: 100px; }
    #vendor-success-root .vendor-success-content-wrapper .add-new-button {
      height: 45px;
      width: 150px;
      border-radius: 25px;
      font-size: 18px;
      line-height: 24px;
      background-color: #00acf1;
      color: #ffffff;
      text-transform: capitalize; }
      #vendor-success-root .vendor-success-content-wrapper .add-new-button:focus {
        outline: none; }
      #vendor-success-root .vendor-success-content-wrapper .add-new-button:hover {
        background-color: #00acf1;
        color: #ffffff; }
