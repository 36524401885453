@media (min-width: 1024px) {
  #mo-footer .container .play-google, #mo-footer .container .icon-holder, #mo-footer .container .company-info {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex; } }

@media (min-width: 768px) and (max-width: 1024px) {
  #mo-footer .container .play-google, #mo-footer .container .icon-holder, #mo-footer .container .company-info {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex; } }

#mo-footer {
  width: 100%;
  height: auto;
  background-color: #595F66;
  position: relative;
  padding: 20px 0;
  font-family: Helvetica !important;
  -webkit-font-smoothing: auto !important; }
  @media (max-width: 767px) {
    #mo-footer {
      height: auto; } }
  @media (min-width: 768px) and (max-width: 1024px) {
    #mo-footer {
      height: auto; } }
  @media print {
    #mo-footer {
      display: none; } }
  #mo-footer .container .logo-img {
    width: 99px;
    height: 25px; }
  #mo-footer .container a + p {
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: -0.21px;
    line-height: 16px;
    margin-top: 30px; }
  #mo-footer .container li {
    list-style: none; }
  #mo-footer .container .play-google {
    width: auto;
    padding: 0;
    cursor: pointer; }
    @media (max-width: 767px) {
      #mo-footer .container .play-google {
        width: 100%;
        position: relative;
        left: 0;
        top: 0;
        display: flex; } }
    #mo-footer .container .play-google li:first-child img {
      margin-left: -12px; }
      @media (max-width: 767px) {
        #mo-footer .container .play-google li:first-child img {
          margin-left: 0; } }
    #mo-footer .container .play-google li:last-child img {
      width: 153px;
      margin: 11px 0 0 0; }
      @media (max-width: 767px) {
        #mo-footer .container .play-google li:last-child img {
          width: 123px; } }
    #mo-footer .container .play-google li img {
      width: 190px; }
      @media (max-width: 767px) {
        #mo-footer .container .play-google li img {
          width: 160px; } }
  #mo-footer .container .icon-holder {
    align-items: flex-end; }
    @media (max-width: 767px) {
      #mo-footer .container .icon-holder {
        display: flex; } }
    #mo-footer .container .icon-holder p {
      margin: 0 20px 0 0;
      color: #FFFFFF;
      font-size: 14px;
      letter-spacing: -0.1px; }
      @media (max-width: 767px) {
        #mo-footer .container .icon-holder p {
          font-size: 13px;
          margin: 0 20px 0 0; } }
      @media (min-width: 768px) and (max-width: 1024px) {
        #mo-footer .container .icon-holder p {
          margin: 0 20px 0 0; } }
    #mo-footer .container .icon-holder i {
      color: #FFFFFF;
      padding: 0 10px 0 0;
      font-size: 32px;
      cursor: pointer; }
      @media (max-width: 767px) {
        #mo-footer .container .icon-holder i {
          font-size: 22px; } }
  #mo-footer .container .company-info {
    justify-content: flex-end; }
    #mo-footer .container .company-info a {
      color: #FFFFFF; }
    @media (max-width: 767px) {
      #mo-footer .container .company-info {
        display: flex;
        justify-content: flex-start;
        padding-top: 10px; }
        #mo-footer .container .company-info ul:first-child {
          padding-inline-start: 0; } }
    #mo-footer .container .company-info li {
      color: #FFFFFF;
      font-size: 12px;
      letter-spacing: -0.21px;
      line-height: 26px;
      cursor: pointer; }
      #mo-footer .container .company-info li:first-child {
        font-size: 20px;
        cursor: text; }
        @media (max-width: 767px) {
          #mo-footer .container .company-info li:first-child {
            font-size: 17px;
            font-weight: 700; } }
