#report-form-details-root {
  width: 100%;
  height: 100%;
  padding-left: 26px; }
  #report-form-details-root .form-details-header {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center; }
    #report-form-details-root .form-details-header i {
      color: #424c9f;
      font-size: 18px; }
    #report-form-details-root .form-details-header p {
      color: #4a4a4a;
      font-size: 24px;
      margin-bottom: 0; }
  #report-form-details-root .form-details-content {
    width: 100%;
    height: 90%; }
    #report-form-details-root .form-details-content .form-details-content-wrapper {
      width: 95%;
      height: 100%; }
      #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary {
        width: 100%;
        min-height: 25%;
        border-radius: 10px 10px 0 0;
        background-color: #e5eaef;
        display: flex; }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary .primary-img-wrapper {
          width: 170px;
          display: flex;
          justify-content: center;
          align-items: center; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary .primary-img-wrapper .primary-img {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            background-color: #ffffff; }
            #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary .primary-img-wrapper .primary-img img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              cursor: pointer; }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary .primary-fields {
          width: 60%;
          padding: 15px 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary .primary-fields .field-data-wrapper {
            width: 50%;
            padding-bottom: 10px; }
            #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary .primary-fields .field-data-wrapper h6 {
              color: #4a4a4a;
              font-size: 12px;
              margin-bottom: 0; }
            #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary .primary-fields .field-data-wrapper h5 {
              color: #4a4a4a;
              font-size: 16px;
              margin-bottom: 0; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary .primary-fields .contact-details {
            width: 50%;
            padding-bottom: 10px;
            display: flex;
            align-items: center; }
            #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary .primary-fields .contact-details i {
              font-size: 15px;
              color: #00acf1;
              padding-right: 15px; }
            #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-primary .primary-fields .contact-details p {
              font-size: 16px;
              color: #4a4a4a;
              margin-bottom: 0; }
      #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary {
        width: 100%;
        min-height: 75%; }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .appbar-root {
          color: #ffffff;
          font-size: 16px;
          background-color: #00acf1;
          box-shadow: none; }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .button-root {
          color: #ffffff;
          font-size: 16px; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .button-root:focus {
            outline: none; }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .selected-button {
          font-size: 18px;
          font-weight: bold; }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .indicator-bar {
          height: 3px;
          margin-bottom: 5px;
          background-color: #ffffff; }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .field-data-wrapper {
          padding-bottom: 10px; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .field-data-wrapper h6 {
            color: #4a4a4a;
            font-size: 12px;
            padding-bottom: 5px;
            margin-bottom: 0; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .field-data-wrapper h5 {
            color: #4a4a4a;
            font-size: 16px;
            margin-bottom: 0;
            width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .field-data-wrapper .longtext-span {
            color: #4a4a4a;
            font-size: 16px;
            margin-bottom: 0; }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .activity-data-wrapper {
          padding-bottom: 10px;
          width: 100%; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .activity-data-wrapper h6 {
            color: #4a4a4a;
            font-size: 14px;
            margin-bottom: 0; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .activity-data-wrapper h5 {
            color: #4a4a4a;
            font-size: 16px;
            margin-bottom: 0; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .activity-data-wrapper .data-wrapper {
            display: flex;
            align-items: center;
            padding-bottom: 2px; }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .w25-pb30 {
          width: 25%;
          padding-bottom: 30px; }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .button-wrapper {
          padding-bottom: 20px;
          display: flex;
          justify-content: center;
          align-items: center; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .button-wrapper .form-update-button {
            height: 45px;
            width: 320px;
            border-radius: 25px;
            font-size: 18px;
            line-height: 24px;
            background-color: #00acf1;
            color: #ffffff;
            text-transform: capitalize; }
            #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .button-wrapper .form-update-button:focus {
              outline: none; }
            #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .button-wrapper .form-update-button:hover {
              background-color: #00acf1;
              color: #ffffff; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .button-wrapper .form-update-disabled-button {
            height: 45px;
            width: 320px;
            border-radius: 25px;
            font-size: 18px;
            line-height: 24px;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
            text-transform: capitalize; }
            #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .button-wrapper .form-update-disabled-button:focus {
              outline: none; }
            #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .button-wrapper .form-update-disabled-button:hover {
              background-color: rgba(0, 0, 0, 0.12);
              color: rgba(0, 0, 0, 0.26); }
        #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .longtext-wrapper {
          width: 95%;
          display: flex;
          flex-direction: row;
          align-items: center; }
          #report-form-details-root .form-details-content .form-details-content-wrapper .form-details-content-secondary .longtext-wrapper .longtext-span-wrapper {
            width: 80%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden; }
